@if (this.userService.user$ | async; as user) {
  <div class="h-full w-full flex flex-col">
    <div class="medinbox-logo-home" [routerLink]="''">
      <button class="log-out justify-self-end mt-1" (click)="logOutEquipment()">
        <mat-icon svgIcon="log-out-icon"></mat-icon>&nbsp;{{ 'GLOBAL.EXIT' | translate }}
      </button>
      <img src="assets/medinbox-logo.png" alt="medinbox logo" class="logo-home justify-self-center -mt-3" />
    </div>

    <div class="flex-grow grid grid-cols-3 gap-6 w-full overflow-auto mt-8">
      <div class="flex flex-col h-full items-center px-8">
        <div class="w-full">
          <div class="page-title mb-4">
            <span>{{ user?.firstName }}{{ user?.lastName }}</span>
          </div>
          <button mat-raised-button class="button mt-4 !w-full" (click)="startNewLive()">
            {{ 'MAIN.HOME.START_LIVE' | translate }}
          </button>
          <h2 class="page-title mt-5">{{ 'MAIN.HOME.TO_COME' | translate }}</h2>
          @for (upcomingEvent of upcomingEvents; track $index) {
            <app-upcoming-event-card class="w-full" [upcomingEvent]="upcomingEvent"></app-upcoming-event-card>
          }
          @if (upcomingEvents.length == 0) {
            <span class="mt-3">{{ 'MAIN.HOME.NO_EVENTS' | translate }}</span>
          }
        </div>
      </div>
      <app-calendar
        class="flex flex-col h-full home-drawing col-span-2 px-8"
        [calendarEvents]="calendarEvents"
        [hasUserEquipment]="true"
        [isMedinboxEquipment]="true"
      ></app-calendar>
    </div>
    <footer>
      <app-terms-and-conditions class="conditions"></app-terms-and-conditions>
    </footer>
  </div>
}
